import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ParticlesScreen from "../components/particles";
import "../styles/portfolio.css"
import ReactDOM from "react-dom";
import Card from '../components/projectCard'
import { useState } from 'react'
import { data } from '../assets/projectinfo'


function Portfolio() {

    const [places, setPlaces] = useState(data)

    return (
        <div className="test">
            <Navbar />
            <div className="particle-container">
                <ParticlesScreen />
            </div>
            <div className="portfolio-content">
                {places.map((place, i) => <Card key={i} info={place} />)}
            </div>
            <Footer />
        </div>
    );
}

export default Portfolio;


