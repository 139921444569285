import "../styles/cards.css"
// import { ReactComponent as Star } from '../assets/star.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGithub,
    faFigma,
} from "@fortawesome/free-brands-svg-icons";
function ProjectCard(props) {


    return (
        <div className="test">
            <div className="card">

                <img src={props.info.image} alt="ProjectBanner" className="card-image" />
                <div className="card-info">
                    <h5 className="card-title info-space">{props.info.name}</h5>
                    <p className="card-description info-space">{props.info.description}</p>
                    <p className="card-tags info-space">{props.info.tags.join(" • ")}</p>
                    <div className="card-links info-space">
                        <a href={props.info.figma} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFigma} className="icons" />
                        </a>
                        <a href={props.info.github} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faGithub} className="icons" />
                        </a>
                    </div>

                </div>



            </div>
        </div>
    );
}

export default ProjectCard;

