import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ContactUs from "../components/contactform"

import "../styles/contact.css"

function Contact() {
    return (
        <div>
            <Navbar/>
            <div className="contact-page">
                <h2 className="contact-title">Let's Work Together</h2>
                <ContactUs/>
            </div>
            <Footer/>
        </div>
    );
  }
  
  export default Contact;