import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../assets/logo512.png';
import '../styles/navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navbar">
      <NavLink className="navlink-style" to="/" style={({ isActive }) => ({ color: isActive ? '#51C8C0' : 'white' })}>
        <img className="nav-icon" src={Logo} alt="logo" />
      </NavLink>
      <div className={`menu ${isOpen ? 'open' : ''}`}>
        <NavLink className="navlink-style" to="/portfolio" style={({ isActive }) => ({ color: isActive ? '#51C8C0' : 'white' })}>
          PORTFOLIO
        </NavLink>
        {/* <NavLink className="navlink-style" to="/blog" style={({ isActive }) => ({ color: isActive ? '#51C8C0' : 'white' })}>
          BLOG
        </NavLink>
        <NavLink className="navlink-style" to="/zworld" style={({ isActive }) => ({ color: isActive ? '#4B3394' : 'white' })}>
          Z-WORLD
        </NavLink> */}
        <NavLink className="navlink-style" to="/contact" style={({ isActive }) => ({ color: isActive ? '#79D8AB' : 'white' })}>
          CONTACT
        </NavLink>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  );
}

export default Navbar;
