import React, { useEffect, useState } from 'react';
import '../styles/landing.css';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { ReactTyped } from "react-typed";
import headshot from '../assets/headshot.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGithub,
    faInstagram,
    faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

function App() {
    const [lineHeight, setLineHeight] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.body.offsetHeight;

            const scrollPercent = (scrollTop) / (documentHeight - windowHeight) / 20;
            const newLineHeight = (scrollPercent * documentHeight) / 1.5;

            setLineHeight(newLineHeight);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='landing-div'>
            <Navbar />
            <div className='main-div'>
                <ReactTyped
                    startWhenVisible
                    strings={[
                        "Creativity", "Innovation", "Design", "Leadership",
                    ]}
                    typeSpeed={40}
                    backSpeed={50}

                    loop
                />
            </div>
            <div className='about-div'>
                <div className='design-bg'>
                    <div className='designs '>
                        <div className='about-header-div'>
                            <h2 className='about-header'>Shaiza Hashmi</h2>
                        </div>
                        <div className='about-content'>
                            <div className='about-left'>
                                <img src={headshot} className='headshot' alt='' />

                                <div className='quote-block'>
                                    <FontAwesomeIcon icon={faQuoteLeft} className='quotes open' />
                                    <h3 className='quote-text'>There are 2 most important days in your life. The day you are born, and the day you find out...why.</h3>
                                    <FontAwesomeIcon icon={faQuoteRight} className='quotes close' />
                                    <h3 className='quote-text'>- Mark Twain</h3>


                                </div>



                            </div>
                            <div className='about-right'>
                                <div className="social-icons-about">
                                    <a href="https://www.instagram.com/_.shaixa/">
                                        <FontAwesomeIcon icon={faInstagram} className="about-icons" />
                                    </a>
                                    <a href="https://github.com/Kayeris">
                                        <FontAwesomeIcon icon={faGithub} className="about-icons" />
                                    </a>
                                    <a href="https://www.linkedin.com/in/shaiza-hashmi/">
                                        <FontAwesomeIcon icon={faLinkedin} className="about-icons" />
                                    </a>
                                </div >
                            </div>
                        </div>





                    </div>
                </div>

            </div>
            <div className='exp-div'>
                <div className='exp-designs'>
                    <div className='pos-header'>
                        <h2 className='section'>Experience </h2>
                        {/* <a href="../assets/resume.pdf" target="_blank" rel="noopener noreferrer">
                        <button>Download Resume</button>
                    </a> */}
                    </div>
                    <div className="timeline">
                        <div className="line" style={{ height: `${lineHeight}%` }}></div>
                    </div>
                    <div className='pos-list td-software-engineer'>
                        <div className='pos-header' >
                            <h3>Software Engineer</h3>
                            <p>Toronto, ON</p>
                        </div>
                        <div className='pos-info' >
                            <h4>TD CANADA</h4>
                            <h4>Jan 2024 -</h4>
                        </div>
                        <ul>
                            <li>Successfully integrated third-party APIs into business banking systems by enhancing functionality and interoperability, improving overall
                                system performance by 10%.</li>
                            <li>Conducted performance and vulnerability analysis and optimizations for key software components leading to improvement in application
                                response times and increased system security to 90%</li>
                            <li>Collaborated with cross-functional teams to ensure alignment with business goals and deliver high-quality software solutions, resulting
                                in successful project completion within established timelines. </li>
                            <li>Won the TD Innovation Challenge, emerging victorious among 100 participants, showcasing creativity, problem-solving skills, and innovative
                                thinking in addressing complex challenges.</li>
                            <li>Leveraged my leadership skills and passion for community engagement as a TD Campus Influencer to promote TD initiatives and foster
                                meaningful connections within the campus community.</li>
                        </ul>
                    </div>
                    <div className='pos-list ta-guelph'>
                        <div className='pos-header' >
                            <h3>Teaching Assistant</h3>
                            <p>Guelph, ON</p>
                        </div>
                        <div className='pos-info' >
                            <h4>UNIVERSITY OF GUELPH</h4>
                            <h4>Aug 2023 - June 2024</h4>
                        </div>
                        <ul>
                            <li>Collaborate with the instructor to strategize lesson planning, and lead instructive sessions on Object-Oriented Programming and UI
                                Design concepts.</li>
                            <li>Conduct scheduled office hours to offer one-on-one support to students. This included elucidating intricate concepts and offering
                                personalized guidance. </li>
                            <li>Assist with grading assignments, quizzes, exams, and other assessments. Provided constructive feedback to improve their performance
                                to 350+ students.</li>
                            <li>Contribute significantly to the technological augmentation of the class by training an AI model to power a custom FAQ chatbot, meticulously
                                crafting written prompts for seamless interaction and ensuring that students had access to crucial course information and assistance
                                24/7.</li>
                        </ul>
                    </div>
                    <div className='pos-list gryphhacks'>
                        <div className='pos-header' >
                            <h3>CoFounder, CoPresident</h3>
                            <p>Guelph, ON</p>
                        </div>
                        <div className='pos-info' >
                            <h4>GRYPHHACKS</h4>
                            <h4>May 2021 - May 2023</h4>
                        </div>
                        <ul>
                            <li>Collaborate with the instructor to strategize lesson planning, and lead instructive sessions on Object-Oriented Programming and UI
                                Design concepts.</li>
                            <li>Conduct scheduled office hours to offer one-on-one support to students. This included elucidating intricate concepts and offering
                                personalized guidance. </li>
                            <li>Assist with grading assignments, quizzes, exams, and other assessments. Provided constructive feedback to improve their performance
                                to 350+ students.</li>
                            <li>Contribute significantly to the technological augmentation of the class by training an AI model to power a custom FAQ chatbot, meticulously
                                crafting written prompts for seamless interaction and ensuring that students had access to crucial course information and assistance
                                24/7.</li>
                        </ul>
                    </div>
                    <div className='pos-list IGEM'>
                        <div className='pos-header' >
                            <h3>Web Developer, UI Designer</h3>
                            <p>Guelph, ON</p>
                        </div>
                        <div className='pos-info' >
                            <h4>IGEM GUELPH</h4>
                            <h4>Mar 2022 - Aug 2022</h4>
                        </div>
                        <ul>
                            <li>Conceptualized design concepts and logos for the product site to a developed brand identity guide for the organization through market
                                research and symbolism.</li>
                            <li>Collaborated with a team of designers and developers to achieve project objectives, as well as biochemists and students to showcase
                                a new organism they created.</li>
                            <li>Developed low-fidelity wireframes and converted them into high-fidelity designs using Figma and Adobe XD</li>
                            <li>Implemented 100% responsive designs using React to create dynamic web pages.</li>
                        </ul>
                    </div>
                    <div className='pos-list ironwall'>
                        <div className='pos-header' >
                            <h3>Frontend Developer, UI Designer</h3>
                            <p>Remote</p>
                        </div>
                        <div className='pos-info' >
                            <h4>IRONWALL INC</h4>
                            <h4>May 2021 - Aug 2021</h4>
                        </div>
                        <ul>
                            <li>Proficiently developed fully responsive web pages using React, HTML, JavaScript, and CSS to enhance user experience.</li>
                            <li>Collaborated with cross-functional teams consisting of designers and developers to implement and test front-end application features,
                                ensuring seamless integration of design and functionality</li>
                            <li>Demonstrated a keen eye for detail, refining user interface design through the implementation of wireframes to achieve optimal usability
                                and aesthetic appeal.</li>
                            <li>Successfully operated in an agile environment, participating in weekly stand-ups and managing user bugs effectively through Github
                                Projects.</li>
                        </ul>
                    </div>
                    <div className='pos-list ccubed'>
                        <div className='pos-header' >
                            <h3>CoFounder, Director of Marketing</h3>
                            <p>Remote</p>
                        </div>
                        <div className='pos-info' >
                            <h4>COMPUTING COUNCILS OF CANADA (NPO)</h4>
                            <h4>Oct 2020 - Dec 2022</h4>
                        </div>
                        <ul>
                            <li>Develop and organize marketing graphics, announcements, and event information for affiliated student clubs.</li>
                            <li>Manage 7+ social media accounts, engaging with over 1,000 students nationwide and 30+ equivalent computer science student governments
                                to cultivate beneficial relationships and promote events.</li>
                            <li>Designed and conceptualized version 1.1 of the organization's website.</li>
                            <li>Developed a brand identity guide to establish a cohesive visual identity.</li>
                            <li>Collaborated with graphic designers and web developers to create informative widgets and improve website interaction by 30%.</li>
                        </ul>
                    </div>
                    <div className='pos-list socis'>
                        <div className='pos-header' >
                            <h3>VP Marketing, VP Finance</h3>
                            <p>Guelph, ON</p>
                        </div>
                        <div className='pos-info' >
                            <h4>(SOCIS) SOCIETY OF COMPUTING INFORMATION SCIENCE</h4>
                            <h4>May 2020 - May 2022</h4>
                        </div>
                        <ul>
                            <li>Implemented effective tracking methods for revenue and expenses as well as designed and reviewed budgets for various departments.</li>
                            <li>Provided financial advice to the President and executive team for strategic planning of events and initiatives.</li>
                            <li>Developed and implemented a comprehensive marketing plan to enhance brand awareness.</li>
                            <li>Created and monitored marketing campaigns, making improvements as necessary.</li>
                        </ul>
                    </div>
                    <div className='pos-list gcc'>
                        <div className='pos-header' >
                            <h3>President</h3>
                            <p>Guelph, ON</p>
                        </div>
                        <div className='pos-info' >
                            <h4>(GCC) GUELPH CODING COMMUNITY</h4>
                            <h4>Jan 2020 - May 2022</h4>
                        </div>
                        <ul>
                            <li>Developing and implementing strategic plans for the club's growth and success, including setting goals and objectives.</li>
                            <li>Fostering relationships with other clubs, organizations, and individuals to collaborate on events and initiatives.</li>
                            <li>Representing the club and advocating for its interests to the broader campus community and beyond.</li>
                            <li>Ensuring that the club is in compliance with all university policies and procedures.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default App;
