import "../styles/footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import {
    faGithub,
    faInstagram,
    faLinkedin,
} from "@fortawesome/free-brands-svg-icons";



function Footer() {
    return (
        <div className="footer">
            <div className="row-display">
                <p> Connect on Creativity, Code and Career!</p>
                <div className="social-icons">
                    <a href="https://www.instagram.com/_.shaixa/">
                        <FontAwesomeIcon icon={faInstagram} className="icons" />
                    </a>
                    <a href="https://github.com/Kayeris">
                        <FontAwesomeIcon icon={faGithub} className="icons" />
                    </a>
                    <a href="https://www.linkedin.com/in/shaiza-hashmi/">
                        <FontAwesomeIcon icon={faLinkedin} className="icons" />
                    </a>
                </div >
            </div >
        </div >
    );
}

export default Footer;