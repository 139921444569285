export const data = [
    {
        "name": "Project BayMax",
        "description": "Your personal healthcare companion, inspired by the beloved Baymax from the movie Big Hero 6!",
        "image": require("./projects/Project BayMax.png"),
        "github": "https://github.com/Kayeris/project-baymax",
        "tags": [
            "AI",
            "NextJS",
            "3D Model"
        ]
    },
    // {
    //     "name": "Character Codex",
    //     // "description": "Your personal healthcare companion, inspired by the beloved Baymax from the movie Big Hero 6! This interactive AI comes equipped with a cloned synthesized voice, perfectly emulating Baymax's comforting tone. With a conversational and caring nature akin to Baymax's, this AI companion is designed to provide personalized healthcare assistance and support. Whether it's answering health-related queries or offering gentle reminders, this virtual assistant aims to emulate the warmth and empathy of everyone's favorite healthcare companion.",
    //     "description": "From intricate backstories to the works they grace, this platform offers a seamless experience for character exploration and management.",
    //     "image": require("./projects/Project PACS.png"),
    //     "github": "https://github.com/Kayeris/project-baymax",
    //     "tags": [
    //         "AI",
    //         "NextJS",
    //         "3D Model"
    //     ]
    // },
    {
        "name": "Pomodoro",
        "description": "Say goodbye to distractions and hello to laser-focused study sessions with the Pomodoro Study Chrome Extension.",
        "image": require("./projects/Project Pomodoro.png"),
        "github": "https://github.com/Kayeris/project-pomodoro",
        "tags": [
            "JavaScript",
            "PHP",
            "Chromium"
        ]
    },
    {
        "name": "TrackFolio",
        "description": "With powerful features tailored to the needs of freelancers and small businesses, this app is your key to efficient client management and order tracking",
        "image": require("./projects/Project Trackfolio.png"),
        "github": "https://github.com/Kayeris/TrackFolio",
        "tags": [
            "Android App",
            "Flutter",
            "Dart"
        ]
    },
    {
        "name": "Project Redesign",
        "description": "This project serves as a testament to my creative adaptability and proficiency in translating diverse design visions into compelling digital experiences.",
        "image": require("./projects//Project Villa Di Manno.png"),
        "github": "https://github.com/Kayeris/project-baymax",
        "tags": [
            "Figma",
            "UI/UX",
            "Adobe CC"
        ]
    },
    {
        "name": "Zengine",
        "description": "Prioritizing privacy and ethical AI, this platform generates high-quality, engaging search results, ensuring a seamless and enriching browsing experience for all users.",
        "image": require("./projects/Project Zengine.png"),
        "github": "https://github.com/Kayeris/zen",
        "tags": [
            "AI",
            "React",
            "Search Engine"
        ]
    },
]