import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function Form() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm("service_testing", "template_1kv6uxe", form.current, { publicKey: "JvXI2SOeggtXouxeR", })
            .then(
                () => {
                    console.log("SUCCESS!");

                },
                (error) => {
                    console.log("FAILED..", error.text);
                }
            );
        e.target.reset();
    };

    return (
        <div>
            <form ref={form} onSubmit={sendEmail}>
                <div className="contact-component">
                    <div className="basic-info">
                        <input type="text" name="user_name" placeholder="  Name" className="glowing-border" />
                        <input type="text" name="user_email" placeholder="  Email" className="glowing-border" />
                    </div>
                    <div className="message-submit">
                        <textarea name="message" placeholder="  Enter your message here:" className="glowing-border" />
                    </div>
                    <div className="message-submit">
                        <input type="submit" value="SEND" className="glowing-border" />
                    </div>
                </div>

            </form>
        </div>
    );
}
export default Form;