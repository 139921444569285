import Navbar from "../components/navbar";
import Footer from "../components/footer"

function Landing() {
    return (
        <div>
            <Navbar/>
            <h3>error 404</h3>
            <Footer/>
        </div>
    );
  }
  
  export default Landing;